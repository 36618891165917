import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import n6 from "../../images/news6.jpg";
import NFooter from "../../components/nFooter";

const News = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="iTelaSoft Partners Mobitel on IoT Devices"
        description="iTelaSoft who have been engaged in developing IoT solutions for both global &amp; local organizations, handed over its first set of NB-IoT modems to Mobitel to facilitate its innovative solution platforms."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <img alt="iTelaSoft Partners" src={n6} />
        </div>
        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">NEWS | JUNE 12, 2019</div>
              <h1>iTelaSoft Partners Mobitel on IoT Devices</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-5 mb-6">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="content-body">
              <div className="content">
                <p>
                  iTelaSoft who have been engaged in developing IoT solutions
                  for both global &amp; local organizations, handed over its
                  first set of NB-IoT modems to Mobitel to facilitate its
                  innovative solution platforms. NB-IoT modem was a part of the
                  migration kit which allows existing sensors and solutions
                  based on legacy standards (GPRS, EDGE, UMTS) to be migrated to
                  narrowband IoT standards (Cat M1, Cat NB1). This allows the
                  existing infrastructure to be modernised, with many benefits
                  enabled by narrowband IoT technology. iTelaSoft strives to
                  help organizations take a leap in technology, by making things
                  simple.
                </p>
                <div className="p-2">
                  <img className="img-fluid" alt="IoT team" src={n6} />
                </div>
                <p className="text-center pb-2">
                  In the Picture ( Vajira Rupesinghe -software engineer
                  iTelaSoft , Chrishmal Fernando – Head of IoT iTelaSoft, Shamil
                  Appathurai- Senior Manager – Network Strategy & Transformation
                  at Mobitel (Pvt) Ltd, Suresh Tissaaratchy – Country Head
                  iTelaSoft)
                </p>
              </div>
            </div>
          </div>
        </div>
        <NFooter
          prv1="/noteworthy/empowering-the-next-generation"
          prv2="/noteworthy/empowering-the-next-generation"
          next1="/noteworthy/iTelaSoft-CEO-Honored-at-the-IESL-NSW-Awards"
          next2="/noteworthy/iTelaSoft-CEO-Honored-at-the-IESL-NSW-Awards"
          titlel="Empowering the next generation"
          titler="iTelaSoft CEO Honored at the IESL NSW Awards"
        />
      </div>
    </Layout>
  );
};

export default News;
